<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2> Screening Antibody - Input Barcode</h2>
    </b-col>
    <b-col class="pl-16 pl-sm-32 py-24 py-sm-32">
    <b-row>
      <b-col cols="5">
        <div class="d-flex justify-content-between">
          <ul>
            <div class="d-block mb-3">
              <label>Kode Kantong</label>
              <li>
                <img src="../../../../../assets/img/logo/Vector.png">
              </li>
            </div>
          </ul>
          <ul>
            <div class="pt-10 mb-3">
              <b-form-textarea 
              v-model="screeningData.barcode"
              @input="saveToLocalStorage"
              type="text"
              :rows="4"
              />
              <!-- <b-form-invalid-feedback :state="v$.screeningData.barcode.$dirty ? !v$.screeningData.barcode.$error : null">
                {{ errorMessages.barcode }}
              </b-form-invalid-feedback> -->
            </div>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <label>Metode  Input Hasil Screening</label>
      </b-col>
      <b-col cols="8">
        <b-form-group class="mb-3">
          <b-form-radio v-model="selected" value="A">Set Hasil Kantong Satu PerSatu</b-form-radio>
          <b-form-radio v-model="selected" value="B">Semua Kantong Akan Menghasilaknan Kantong Yang Sama</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row >
      <b-col cols="4">
        </b-col>
        <b-col cols="8">
          <b-row class="mb-3">
            <b-col cols="4" >
              <label>Antibody</label>
            </b-col>
            <b-col cols="3" >
              <b-form-select
                v-model="screeningData.syphilis"
                @input="saveToLocalStorage"
                :options="options"
              />
              <!-- <b-form-invalid-feedback :state="v$.screeningData.syphilis.$dirty ? !v$.screeningData.syphilis.$error : null">
                {{ errorMessages.syphilis }}
              </b-form-invalid-feedback> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    <b-col>
      <div class="d-flex justify-content-end mb-3">
          <b-button
              variant="primary-1 "
              type="submit"
              :loading="isBusy"
              @click="chekingScreeningAntibody"
          >
            <b-spinner
              small 
              label="Small Spinner" 
              v-if="isBusy"
            />
            <span v-else>Lanjut</span>
          </b-button>
        </div>
    </b-col>
  </b-col>
</b-row>
</template>

<script>
import { 
BButton, 
BCol, 
BRow, 
BFormInput,
BFormTextarea,
BFormRadio,
BFormGroup,
BFormSelect,
BFormInvalidFeedback,
BSpinner,
} from 'bootstrap-vue';
import {useVuelidate} from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
// import fieldMappings from './data/fieldMappings'

export default {
setup: () => ({ v$: useVuelidate() }),
name: 'add-screening-antibody',
 components: {
  BButton,
  BCol,
  BRow,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BFormGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BSpinner,
 },
 data() {
  return {
    screeningData: {
      barcode:'',
      antibody: '',
    },
    errorMessages: {},
    selected: 'A',
    options: [
      { value: '1', text: 'Positif' },
      { value: '0', text: 'Negatif' },
    ],
    
  }
},
validations() {
    return {
      screeningData: {
        barcode: { required, minLength: minLength(9) },
        antibody: {required},
      },
    }
  },
computed: {
    isBusy() {
      return this.$store.state.manageScreeningAntibody.isBusy
    },
},
watch: {
  selected: function () {
    return this.selected === 'B'
  }
},
mounted() {
},

methods: {
  async saveToLocalStorage() {
    await localStorage.setItem('screeningData', JSON.stringify(this.screeningData));
  },
  async chekingScreeningAntibody() {
    const formData = new FormData()
      formData.append('barcode', this.screeningData.barcode)
      formData.append('antibody', this.screeningData.antibody)

      await this.$store.dispatch('manageScreeningAntibody/chekingScreeningAntibody', formData)
      .then(() => {
        this.$router.push({name: 'add-next-screeningantibody'})
        this.$bvToast.toast('Data Barcode Ada', {
            title: 'Success',
            variant: 'success',
            solid: true,
          });
       })
      //  .catch(err => {
      //   if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
      //     const errorMessage = err.response.data.message
      //     Object.keys(this.screeningData).forEach(field => {
      //       if (this.v$.screeningData[field]) {
      //         this.v$.screeningData[field].$error = true
      //         this.v$.screeningData[field].$touch()
      //         this.$set(this.errorMessages, field, fieldMappings[field], errorMessage)
      //       }
      //     })
      //   }
      // })
  },
},
}
</script>
<style scoped>

</style>